export default {
    selectedAccommodations: {
        handler: function (accommodations) {
            this.$store.commit('updateCartAccommodations', accommodations)
        },
        deep: true
    },
    selectedOptionals: {
        handler: function (optionals) {
            this.$store.commit('updateCartOptionals', optionals)
        },
        deep: true
    }
}
