import computed from './attrs/computed'
import data from './attrs/data'
import methods from './attrs/methods'
import mounted from './attrs/mounted'
import props from './attrs/props'
import watch from './attrs/watch'

import CartOverview from '../../components/CartOverview/Index'
import VueNumericInput from 'vue-numeric-input'

export default {
    name: 'Accommodations',
    computed,
    data,
    methods,
    mounted,
    props,
    watch,
    components: {
        CartOverview,
        VueNumericInput
    }
}
