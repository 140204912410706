export default function() {
    this.loadTrip()

    if (window.dataLayer) {
        window.dataLayer.push({event: 'pageview', cart: this.$store.state.cart, page: 'selectAccommodations'});
    }

    this.selectedBoardingPlace = this.$store.state.cart.boardingPlace
    this.$store.commit('updateInstallments', {})
    this.$store.state.errors = []
}
