export default function() {
    return {
        nextStep: {
            name: 'Login'
        },
        selectedAccommodations: [],
        selectedOptionals: [],
        selectedBoardingPlace: null
    }
}
