import getApiClient from '@/plugins/api-client'

export default {
    loadTrip() {
        const self = this,
            api = getApiClient(self.$store),
            tripId = self.$route.params.id

        if (! self.$route.params.keepCart) {
            self.$store.commit('resetCart')
            self.$store.dispatch('createCart', tripId)
                .then(() => {})
                .catch(error => {
                    console.log(error)
                })
        }

        self.$store.commit('startLoading')
        api.get(`trips/${tripId}`)
            .then(result => {
                result.data.accommodations.forEach(accommodation => {
                    accommodation.peopleTypeValue.forEach(value => {
                        value.name = `${accommodation.accommodation.name} (${value.peopleType.name})`
                        const isSelected = this.getSelectedAccommodation(value.id)
                        if (isSelected) {
                            value.quantity = isSelected.quantity
                            this.selectedAccommodations.push(value)
                        } else {
                            value.quantity = 0
                        }
                    })
                })

                result.data.optionals.forEach(optional => {
                    const isSelected = this.getSelectedOptional(optional.id)
                    if (isSelected) {
                        optional.quantity = isSelected.quantity
                        this.selectedOptionals.push(optional)
                    } else {
                        optional.quantity = 0
                    }
                })

                result.data.boardingPlaces = result.data.boardingPlaces.filter((boardingPlace) => boardingPlace.status)

                if (result.data.boardingPlaces.length === 1 && ! this.selectedBoardingPlace) {
                    this.selectedBoardingPlace = result.data.boardingPlaces[0]
                    this.setBoardingPlace(this.selectedBoardingPlace.id)
                }

                self.$store.commit('setTrip', result.data)

                if (window.dataLayer) {
                    window.dataLayer.push({
                        event: 'begin_checkout',
                        currency: 'BRL',
                        value: 0,
                        items: [
                            {
                                item_id: result.data.id,
                                item_name: result.data.eventTitle,
                                affiliation: result.data.agency.name
                            }
                        ]
                    })
                }
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                self.$store.commit('stopLoading')
            })
    },

    getSelectedAccommodation(accommodationId) {
        return this.$store.state.cart.accommodations.filter(accommodation => {
            return accommodation.id === accommodationId
        }).pop()
    },

    removeAccommodation(accommodationId) {
        this.selectedAccommodations = this.selectedAccommodations.filter(accommodation => {
            return accommodation.id !== accommodationId
        })
    },

    selectAccommodation(accommodation) {
        const isSelected = this.getSelectedAccommodation(accommodation.id)

        if (isSelected) {
            this.removeAccommodation(accommodation.id)
            accommodation.quantity = 0
            return
        }

        this.selectedAccommodations.push(accommodation)
        accommodation.quantity = 1
    },

    updateAccommodationSelection(accommodation) {
        const isSelected = this.getSelectedAccommodation(accommodation.id)
        if (! accommodation.quantity) {
            accommodation.quantity = 0
        }
        if (isSelected) {
            isSelected.quantity = accommodation.quantity
        }
        if (accommodation.quantity > 0 && !isSelected) {
            this.selectedAccommodations.push(accommodation)
            return
        }

        if (!accommodation.quantity && isSelected) {
            this.removeAccommodation(accommodation.id)
            return
        }
    },

    getSelectedOptional(optionalId) {
        return this.$store.state.cart.optionals.filter(optional => {
            return optional.id === optionalId
        }).pop()
    },

    removeOptional(optionalId) {
        this.selectedOptionals = this.selectedOptionals.filter(optional => {
            return optional.id !== optionalId
        })
    },

    selectOptional(optional) {
        const isSelected = this.getSelectedOptional(optional.id)

        if (isSelected) {
            this.removeOptional(optional.id)
            optional.quantity = 0
            return
        }

        this.selectedOptionals.push(optional)
        optional.quantity = 1
    },

    updateOptionalSelection(optional) {
        const isSelected = this.getSelectedOptional(optional.id)
        if (! optional.quantity) {
            optional.quantity = 0
        }
        if (isSelected) {
            isSelected.quantity = optional.quantity
        }
        if (optional.quantity > 0 && !isSelected) {
            this.selectedOptionals.push(optional)
            return
        }

        if (!optional.quantity && isSelected) {
            this.removeOptional(optional.id)
            return
        }
    },

    setBoardingPlace(boardingPlace) {
        this.$store.commit('updateCartBoardingPlace', boardingPlace)
    }
}
