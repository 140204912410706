import { mapState } from 'vuex'

export default mapState({
    trip: state => state.trip,
    validSelections: function(state) {
        let isValid = true

        if (
            ! state.cart.accommodations.length ||
            (state.trip.boardingPlaces.length && ! state.cart.boardingPlace)
        ) {
            isValid = false
        }

        return isValid
    },
    totalPeople(state) {
        return (
            state.cart.accommodations.map(accommodation => accommodation.quantity).reduce((partial, a) => partial + a, 0)
        )
    },
    errors(state) {
        const result = []

        if (state.trip.boardingPlaces.length && ! state.cart.boardingPlace) {
            result.push({
                hash: '#boardingPlaces-header',
                path: 'boardingPlace',
                message: 'Selecione um embarque'
            })
        }

        if (!state.cart.accommodations.length) {
            result.push({
                hash: '#accommodations-header',
                path: 'accommodations',
                message: 'Selecione uma hospedagem'
            })
        }

        return result
    },

    validationErrors(state) {
        return state.errors
    }
})
